import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";
import {
  useQuery,
} from "@apollo/client";
import Main from "../components/main";
import Layout from "../components/section/layout";
import Seo from "../components/section/seo";
import {getCurrentUserQuery} from "../components/logic/user";
import Loader from "../components/bootstrap/loader";
import LoginControl from "../controls/login";
import Terms from "../components/portal/terms.jsx";
import "../style/custom/login.scss";
import config from "../config";



const Login = (props) => {
  return (
    <Main>
      <Layout>
        <Seo title="Scape - Internet" />
        <UserCheck {...props} />
      </Layout>
    </Main>
  )
};

const UserCheck = (props) => {
  const currentUserQuery = useQuery(getCurrentUserQuery);
  if (currentUserQuery.loading) {
    return <Loader />;
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;
  
  if (user) {
    return window.location = `${config.portal}`;
  }

  return (
    <LoginPage {...props} />
  );
};

const LoginPage = (props) => {
  const dateNow = new Date();
  const year = dateNow && dateNow.getFullYear();
  const [showTerm, setTerm] = useAsyncSetState(false);

  return (
    <Fragment>
       {showTerm && <Terms onClose={() => setTerm(false)} />}
        <Container
            fluid
            className="fluid login-container p-0"
        >
        <Row className="no-gutters">
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
              <section className="login-first-section blur vh-100">
                  <section
                    className="px-4 px-xl-5 login-first-section-main"
                  >
                      <section className="login-form-logo-og">
                        <a href="https://www.scape.com.au/" target="_blank">
                          <img
                            src="https://cdn.vostro.app/cdn/hotspots/scape/Scape_Main%20logo.svg"
                            alt="logo"
                            className="form-logo"
                          />
                        </a>
                      </section>
                      <section className="login-form-logo-white">
                        <a href="https://www.scape.com.au/" target="_blank">
                          <img
                            src="https://cdn.vostro.app/cdn/hotspots/scape/Scape_Main%20logo%20white.png"
                            alt="logo"
                            className="form-logo-white"
                          />
                        </a>
                      </section>
                      <div className="login-form-container d-flex flex-column align-items-center  justify-content-center">
                          <div className="login-first-text">
                            <p>Login to your account</p>
                          </div>
                          <LoginControl 
                            authUrl={config.auth} 
                            portalUrl={config.portal} 
                            apiUrl={config.backend}
                          />
                      </div>
                  </section>
                  <section className="login-form-footer">  
                    {`© Scape ${year}. All rights reserved. | `}
                    <a onClick={() => setTerm(true)}>
                      <span className="login-tnc-link">
                        {"Terms & Conditions"}
                      </span>
                    </a>
                  </section>
              </section>
          </Col>
          <Col 
            lg={8} 
            xl={8} 
            sm={0} 
            md={0} 
            className="d-none d-xs-none d-sm-none d-md-none d-lg-block"
          >  
              {/* <footer>
                <div className="login-footer">  
                  {`© Scape ${year}. All rights reserved. | `}
                  <a onClick={() => setTerm(true)}>
                    <span className="login-tnc-link">
                      {"Terms & Conditions"}
                    </span>
                  </a>
                </div>
              </footer> */}
          </Col>
        </Row>  
    </Container>
    </Fragment>
  );
};

export default Login;
