import React, { useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";
import Formsy from "formsy-react";
import { Link } from "gatsby";

import TextField from "../bootstrap/input";
import Captcha from "../bootstrap/captcha";
import Verification from "../forgot-password/verification";
import Button from "../bootstrap/button";
import config from "../../config";

const RecoverPassword = (props) => {
  const [loading, setLoading] = useAsyncSetState(false);
  const [errorMessage, setErrorMessage] = useAsyncSetState(null);
  const [captchaRefreshKey, setRefreshKey] = useAsyncSetState(new Date());
  const [showVerification, setVerification] = useAsyncSetState(false);
  const [activeEmail, setActiveEmail] = useAsyncSetState(undefined);
  const [requestError, setRequestError] = useAsyncSetState(false);
  const formRef = useRef(null);

  const handleSubmit = async ({ email, captcha, captchaKey }) => {
    try {
      await setLoading(true);
      const response = await fetch(`${config.backend}rest.api/forgot-request`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          captcha,
          captchaKey
        }),
      });
      switch(response.status) {
          case 503:
            // await setStateAsync(() => (<>
            //   {"An error has occurred"}
            // </>));
            await setRefreshKey(new Date());
            break;
          case 401:
            await setRefreshKey(new Date());
            const {error} = await response.json();
            switch(error) {
              case "EEXISTS":
                await setErrorMessage(() => (<>
                  {"This email address already registered, "}
                  <Link to="/forgot-password">{"Click Here"}</Link>{" to reset your password"}
                </>));
                break;
              case "ECAPTCHA":
                await setErrorMessage(() => (<>
                  {"Invalid captcha code provided"}
                </>));
                break;
              default:
                await setErrorMessage(error);
                break;
            }
            break;
          case 200:
            await setActiveEmail(email);
            await setErrorMessage(null);
            await setVerification(true);
        }
      } catch (err) {
        console.log("err", err);
        await setErrorMessage("An error has occurred");
      }
      return setLoading(false);
  }

  return (
    <div className="forgot-password-modal">
      {showVerification ? 
        <Verification setStateVerification={setVerification} /> : (
        <div className="forgot-password-body">
          <Formsy ref={formRef} onValidSubmit={handleSubmit} onChange={() => setErrorMessage(null)}>
            <h3 className="forgot-password-title">Forgot Password?</h3>
            <p className="forgot-password-subtitle">
              Enter the email you use to register, and we'll help you create a new password.
            </p>
            <Row>
              <Col xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  autoComplete="off"
                  disabled={loading}
                  required
                  defaultValue={activeEmail}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Captcha name="captcha" refreshKey={captchaRefreshKey}/>
              </Col>
            </Row>
            {errorMessage ?
               <Row className="pt-3 align-items-center justify-content-center forgot-password-button-container">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="primary"
                      className="request-error"
                      onMouseLeave={() => setRequestError(false)} 
                      disabled={true}
                      fullWidth
                      size="lg"
                    >
                       {errorMessage}
                    </Button>
                  </Col>
             </Row>
             :
              <Row className="pt-3 align-items-center justify-content-center forgot-password-button-container">
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Button
                      variant="primary"
                      className="request-button"
                      type="submit"
                      disabled={loading}
                      onClick={() => formRef.current.submit()}
                      fullWidth
                      size="lg"
                    >
                      REQUEST
                  </Button>
                </Col>
              </Row>
            }
          </Formsy>
        </div>
      )}
    </div>
  )
}

export default RecoverPassword;
