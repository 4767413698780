import React, { Fragment, useRef, useState, useEffect } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import TextField from "../components/bootstrap/input";
import { loginMutation } from "../components/logic/user";
import RecoverPassword from "../components/portal/recover-password.jsx"; 
import { useMutation } from "@apollo/client";
import { fetch, localStorage, window } from "window-or-global";
import { useAsyncSetState } from "use-async-setstate";

const LoginControl = (props) => {
  const { authUrl, portalUrl, apiUrl } = props;
  const [login] = useMutation(loginMutation);
  const formRef = useRef(null);
  const [passwordShow, setPasswordShow] = useState(false);
  const [isMarked, setIsMarked] = useState(false);
  const [processing, setProcessing] = useAsyncSetState(false);
  const [loginError, setLoginError] = useAsyncSetState(false);
  const [authDetails, setAuthDetails] = useAsyncSetState(false);
  const [showForgotPassword, setShowForgotPassword] = useAsyncSetState(false);


  const handleValidLoginSubmit = async (data) => {
    await setProcessing(true);
    try {
      const response = await fetch(`${apiUrl}rest.api/login`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ ...data }),
      });

      if (response.status === 503) {
        await setProcessing(false);
        await setLoginError("Something went wrong")
        return;
      }

      const parsedResponse = await response.json();

      if (parsedResponse?.success === "Invalid username/password") {
        await setProcessing(false);
        await setLoginError("Invalid username and/or password.");
      }

      if (response.status === 401) {
        await setProcessing(false);
        await setLoginError(parsedResponse.error);
      }
      if (parsedResponse.jwtToken) {

        localStorage.setItem("authToken", parsedResponse.jwtToken);
      }

      if (parsedResponse.user.id) {
        await login({ variables: data });
        await setAuthDetails(data)
        if (authUrl && authUrl !== "") {
          return formRef.current.submit();
        } else {
          window.location = portalUrl;
        }
      }
    } catch (err) {
      console.log("login error", err);
      await setProcessing(false);
      await setLoginError("Invalid username and/or password.");
    }

    await setProcessing(false);
  };

  const ToggleSwitch = () => {
    
    const switchComponent = () => {
      setIsMarked((prev) => !prev);
    }

    const checkIsMarkValue = () => {
      const dataType = typeof isMarked;
      if (dataType === "boolean") {
        localStorage.setItem("keepUserLogged", JSON.stringify(isMarked));
      } else {
        window.alert("action not allowed");
      }
    }

    useEffect(() => {
      checkIsMarkValue();
    }, [isMarked]);

    return(
      <Col 
        xl={7} lg={7} sm={7} md={7} xs={7}
        className="vw-login-switch"
      >
        <div 
          className="custom-control custom-switch"
        >
            <input 
              type="checkbox" 
              className="custom-control-input" 
              id="customSwitch1"
              checked={isMarked}
              onChange={() => switchComponent()}
            />
            <label 
              className="custom-control-label pl-1" for="customSwitch1"
              id="vw-user-remember-label"
            >
              Remember me
            </label>
        </div>
      </Col>
    )
  } 

  const forgotPassword = () => {
    return(
      <Col
        xl={5}  
        lg={5} 
        sm={5} 
        md={5} 
        xs={5}
        className="vw-login-recover-password"
      >
        <a 
          className="forgot-password"
          href="/forgot-password"
        >
          {"Forgot Password?"}
        </a>
      </Col>
    )
   
  }

  const signUpLink = () => {
    return(
      <Row className="vw-login-register-question">
        <Col 
            xs={12} 
            sm={12}
            className="vw-login-reg-question"
        >
          <span 
            className="vw-login-account-question"
          >
            Don't have an account?
          </span>
        </Col>
        <Col 
          xs={12} 
          sm={12}
          lg={12}
          className="vw-login-reg-question"
        >
          <a 
            className="vw-login-reg-link"
          >
            {"Sign up"}
          </a>
        </Col>
    </Row> 
    );
  };

  return (
    <Fragment>
      {showForgotPassword && <RecoverPassword onClose={() => setShowForgotPassword(false)} />}
      <Formsy
        onValidSubmit={handleValidLoginSubmit}
        disabled={processing}
      >
        <Container className="px-lg-0 pt-4">
          <Row className="vw-login-username-form-field no-gutters">
            <Col
              lg={12}
              xs={12}
              className="vw-login-form-field"
            >
              <TextField
                className="vw-login-form-field"
                name="userName"
                autoComplete="off"
                id="username-field"
                label="Username"
                required
                hideRequiredMark
                outlined
                suffix={<i className="fas fa-user-tie username-icon"/>}
              />
            </Col>
          </Row>
          <Row className="vw-login-password-form-field no-gutters">
            <Col lg={12} xs={12} className="vw-login-form-field">
              <TextField
                className="vw-login-form-field"
                name="password"
                autoComplete="off"
                id="password-field"
                type={passwordShow ? "text" : "password"}
                label="Password"
                required
                hideRequiredMark
                outlined
                suffix={passwordShow ?
                    <i
                      className="fas fa-eye pass-icon"
                      onClick={() => setPasswordShow(false)}
                    />
                    :
                    <i
                      className="fas fa-eye-slash pass-icon"
                      onClick={() => setPasswordShow(true)}
                    />
                }
              />
            </Col>
          </Row>
          <Row className="vw-login-components no-gutters">
            {ToggleSwitch()}
            {forgotPassword()}
          </Row>
          <Row className="vw-login-buttons no-gutters">
          { processing ? 
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="vw-login-err text-center"
            >
              <Button
                className="btn-login-err"
              >
                <i
                  className="fas fa-spinner fa-pulse login-load"
                />
              </Button>
            </Col>
            :
            <>
              {loginError ?
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="vw-login-err text-center"
                  onMouseLeave={() => setLoginError(false)} 
                >
                  <Button
                    className="btn-login-err"
                  >
                    {loginError}
                  </Button>
                </Col>
              :
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="vw-loginbtn text-center"
                >
                  <Button
                    className="btn-login"
                    type="submit"
                    // disabled={processing}
                  >
                    {"SIGN IN"}
                  </Button>
                </Col>
              }
            </>
          }
          </Row>
         
        </Container>
       
      </Formsy>
      {/* <span className="login-form-subtitle">
        Provide access to the Internet for Universities/ Education Institution
      </span> */}
      <form ref={formRef} name="login" action={authUrl} method="post">
        <input type="hidden" value="hotspot" name="radius11" />
        <input
          type="hidden"
          name="dst"
          value={`${portalUrl}`}
        />
        <input type="hidden" name="username" value={(authDetails?.userName || "").toLowerCase()} />
        <input type="hidden" name="password" value={authDetails?.password} />
      </form>
    </Fragment>
  );
}

export default LoginControl;
