import React, { Fragment } from "react";
import Formsy from "formsy-react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import TextField from "../bootstrap/input";
import Button from "../bootstrap/button";
import { useAsyncSetState } from "use-async-setstate";
import { useRef } from "react";
import { fetch } from "window-or-global";
import config from "../../config";

export default function Verification(props) {
  const {setStateVerification} = props;
  const [loading, setLoading] = useAsyncSetState(false);
  const [errorMessage, setErrorMessage] = useAsyncSetState(null);
  const [verifyError, setVerifyError] = useAsyncSetState(false);
  const formRef = useRef(null);

  const handleVerify = async (data) => {
    await setLoading(true);

    try {
      const { code } = data;
      if (!code) {
        await setLoading(false);
        return;
      }

      const apiUrl = config.backend;

      let result = await fetch(`${apiUrl}rest.api/forgot-verification/${code}`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      });
      result = await result.json();

      if (!result?.success && result?.error) {
        await setLoading(false);
        return await setErrorMessage("Invalid verification code")
      }

      if (result && result?.success && result?.redirectUrl) {
        window.location.href = result?.redirectUrl;
      }
    } catch (err) {
      console.log({err});
      await setLoading(false);
    }

    await setLoading(false);
  }

  return (
    <div className="verification-container">
        <Formsy
          ref={formRef}
          onChange={() => setErrorMessage(null)}
          onValidSubmit={async (data) => {
            return handleVerify(data);
          }}
          onInvalidSubmit={(e) => {
            console.log("error", e);
          }}
        >
          <div>
            <Row>
              <Col xl={12} lg={12}>
                  <span className="verification-title">{"Enter verification code to continue"}</span>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <p className="verification-text">
                  {"A sms or email has been sent to your device, enter the one time code to verify your account to reset your password."}
                </p>
                <p className="verification-text">
                  {"If you do not receive a verification code, please go back and check your details and try again."}
                </p>
              </Col>
            </Row>
            <Row className="verify-field-row">
              <Col xs={12}>
                <TextField
                  disabled={loading}
                  autoFocus
                  name="code"
                  defaultValue=""
                  required
                  autoComplete="one-time-code"
                  label="Verification Code"
                />
              </Col>
            </Row>  
          </div>
          {errorMessage ?
            <Row className="align-items-center  justify-content-center">
              <Col xs={12} sm={12} md={12} lg={12}>
                <Button
                  className="verify-error"
                  onMouseLeave={() => setVerifyError(false)} 
                  disabled={true}
                  fullWidth
                  size="lg"
                >
                  {errorMessage}
                </Button>
              </Col>
            </Row>
            :
            <Row className="verify-button align-items-center justify-content-center">
              <Col xs={12} lg={12}>
                <Button
                  className="verify-reset-password"
                  size="lg"
                  type="submit"
                  disabled={loading}
                  onClick={() => formRef.current.submit()}
                  fullWidth
                >
                  {"RESET PASSWORD"}
                </Button>
              </Col>
            </Row>
          }
        </Formsy>     
        <Row className="verify-return align-items-center justify-content-center">
          <Col lg={12} sm={12}>
            <div
              className="vw-return"
            >
              <span 
                className="return-link"
                onClick={async () => {
                  setStateVerification(false)
                }}
              >
                <i className="fas fa-arrow-left mr-2" />
                {"RETURN"}
              </span>  
            </div>
          </Col>
        </Row>
    </div>
  )
}
