import React, { useState, useEffect, Fragment } from "react";
import { Row, Col } from "react-bootstrap";

import TextField from "./input";
import FormsyHiddenField from "./hidden";
import { fetch } from "window-or-global";
import config from "../../config";

const Captcha = (props) => {
  const [captchaKey, setCaptchaKey] = useState();
  const baseUrl = config.backend;

  const generateCaptcha = async () => {
    if (captchaKey && captchaKey !== "") {
      await fetch(`${baseUrl}captcha.api/destroy/${captchaKey}`);
    }

    const urlGenerate = `${baseUrl}captcha.api/generate`;
    const capGenRes = await fetch(urlGenerate);
    if (capGenRes.status === 200) {
      const { key } = await capGenRes.json();
      setCaptchaKey(key);
    }
  }

  useEffect(() => {
    generateCaptcha();
  }, []);

  if (!captchaKey) {
    return <React.Fragment />;
  }

  let uri = `${baseUrl}captcha.api/image/${captchaKey}`;

  return (
    <Fragment>
      <Row>
        <FormsyHiddenField name="captchaKey" val={captchaKey} />
      </Row>
      <Row>
        <Col xl={12}>
          <TextField
            label="Enter Captcha"
            name="captcha"
            fullWidth
            pattern="[a-zA-Z0-9]+"
            inputMode="numeric"
            autoComplete="off"
            required 
          />
        </Col>
      </Row>
      <Row className="captcha-field">
        <Col
          className="captcha-char"
          xl={8} lg={8} md={5} xs={5}
        >
          <img
            alt="captcha-key-field"
            key={captchaKey}
            src={uri}
            className="img-fluid pull-left"
          />
        </Col>
        <Col
          className="captcha-regen"
          xl={4} lg={4} md={7} xs={7}
        >
          <a
            className="vw-button"
            onClick={() => generateCaptcha()}
          >
            <i 
              className="captcha-refresh fa fa-sync" 
            />
          </a> 
        </Col>
      </Row>
    </Fragment>
  );
}

export default Captcha;


