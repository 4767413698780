import React, { Fragment } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import "../../style/terms-and-conditions.scss"
import TermsAndCondition from "./terms-and-conditions";

const TermModal = (props) => {
  const { onClose } = props;

  return (
    <Fragment> 
      <Modal
        show
        className="terms-modal"
        onHide={() => null}
      >
        <i
          className="fas fa-times-circle close-btn"
          onClick={onClose}
        />
        <Container fluid className="no-gutters">
          <Row className="terms-and-conditions-modal">
            <Col>
              <div className="tnc-terms">
                <TermsAndCondition/>
              </div>
            </Col>
          </Row>  
        </Container>
      </Modal>
    </Fragment>
  );
}

export default TermModal;
